@tailwind base;
@tailwind components;
@tailwind utilities;

/* Color Variables */
:root {
  --light-mode-theme: #FFFCFA;
  --dark-mode-theme: #0C1318;
  --light-mode-text: #090401;
  --dark-mode-text: #EBEff3;
}

/* Custom CSS */
body {
  background-color: var(--light-mode-theme);
  transition: color 1s cubic-bezier(0.075, 0.82, 0.165, 1)
}

body.dark {
  background-color: var(--dark-mode-theme);
  transition: color 1s cubic-bezier(0.075, 0.82, 0.165, 1)
}

.no-scroll {
  overflow: hidden;
}

.header {
  font-weight: bold;
  text-align: center;
  width: fit-content;
  margin-bottom: 4px;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.sub-header {
  font-weight: normal;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
}

.paragraph {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.button-style {
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

/* Define initial styles for navbar */
.navbar-default {
  position: absolute;
  inset: 0;
}

/* Define styles when navbar scrolls */
.navbar-scroll {
  position: fixed;
  top: -100px; /* Adjust this value as needed */
  animation: slideDown 0.5s ease forwards; /* Animation for smooth transition */
}

.hero-container {
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 117px;
}

.in-view {
  animation: cardBounce 1.2s;
  animation-delay: 0.8s;
}

/* Keyframes for slideDown animation */
@keyframes slideDown {
  from {
    top: -100px; /* Adjust this value to the initial position off the screen */
  }
  to {
    top: 0; /* Adjust this value to where you want the navbar to stick */
  }
}

@keyframes cardBounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(5px)
  }
  100% {
    transform: translateX(0);
  }
}

/* Media Query for xs devices */
@media screen and (min-width: 320px) {

}

/* Media query for sm devices */
@media screen and (min-width: 640px) {
  .header {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .paragraph {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .button-style {
    padding: 0.5rem 1rem;
  }
}

/* Media query for md devices */
@media screen and (min-width: 768px) {
  .header {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sub-header {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .hero-container {
    margin-top: 77px;
    height: calc(100vh - 77px);
  }
  .button-style {
    padding: 0.5rem 1.375rem;
  }
}

/* Media query for lg devices */
@media screen and (min-width: 1024px) {
  .in-view {
    animation: none
  }
}
